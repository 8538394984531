<template>
  <div class="data-other">
    <v-form ref="form">
      <v-row class="py-5">
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-model="headdepartment.nip"
              label="NIP"
              outlined
              type="number"
              dense
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-model="headdepartment.nik"
              label="NIK"
              outlined
              type="number"
              dense
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-model="headdepartment.first_degree"
              label="Gelar Depan"
              outlined
              type="text"
              dense
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-model="headdepartment.last_degree"
              label="Gelar Belakang"
              outlined
              type="text"
              dense
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-model="headdepartment.place_of_birth"
              label="Tempat Lahir"
              outlined
              type="text"
              dense
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
          cols="12"
          class="py-0"
        >
          <div>
            <v-menu
              v-model="dateBirth"
              :close-on-content-click="true"
              nudge-right="0"
              nudge-top="20"
              max-width="100%"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="headdepartment.date_of_birth"
                  label="Tanggal Lahir"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  @click="autofillBirthDate"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="headdepartment.date_of_birth"
                :first-day-of-week="0"
                locale="id-id"
                color="primary"
                @input="date = false"
              ></v-date-picker>
            </v-menu>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
          cols="12"
          class="py-0"
        >
          <div>
            <v-autocomplete
              v-model="headdepartment.gender"
              label="Jenis Kelamin"
              outlined
              :items="genders"
              type="text"
              dense
            ></v-autocomplete>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
          cols="12"
          class="py-0"
        >
          <div>
            <v-autocomplete
              v-model="headdepartment.religion"
              label="Agama"
              outlined
              :items="religions"
              type="text"
              dense
            ></v-autocomplete>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-model="headdepartment.phone"
              label="No HP"
              outlined
              type="number"
              dense
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
          cols="12"
          class="py-0"
        >
          <div>
            <v-menu
              v-model="dateWork"
              :close-on-content-click="true"
              nudge-right="0"
              nudge-top="20"
              max-width="100%"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="headdepartment.date_of_work"
                  label="Tanggal Masuk"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  @click="autofillWorkDate"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="headdepartment.date_of_work"
                :first-day-of-week="0"
                locale="id-id"
                color="primary"
                @input="date = false"
              ></v-date-picker>
            </v-menu>
          </div>
        </v-col>
        <v-col
          cols="12"
          class="py-0"
        >
          <FileInput
            v-model="initialFile"
            :value="headdepartment.photo"
            label="Gambar"
            outlined
            dense
            :disabled="false"
            :prependicon="icons.mdiImageMultipleOutline"
            @input="getFile"
          ></FileInput>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import FileInput from '@/views/components/FileInput.vue'
import { mdiImageMultipleOutline } from '@mdi/js'

export default {
  name: 'DataOther',
  components: {
    FileInput,
  },
  props: {
    editUuid: {
      type: String,
      default() {
        return ''
      },
    },
  },
  data() {
    return {
      error: false,
      icons: {
        mdiImageMultipleOutline,
      },
      hint: '',
      dialog: '',
      dateBirth: '',
      dateWork: '',
      genders: ['Laki-Laki', 'Perempuan'],
      religions: ['Islam', 'Kristen Protestan', 'Kristen Khatolik', 'Hindu', 'Budha', 'Konghucu'],
      InitialKadis: {
        nip: '',
        nik: '',
        first_degree: '',
        last_degree: '',
        date_of_birth: '',
        place_of_birth: '',
        gender: '',
        religion: '',
        phone: '',
        photo: null,
        date_of_work: '',
      },
      headdepartment: { ...this.InitialKadis },
    }
  },
  computed: {
    initialFile: {
      get() {
        if (typeof this.headdepartment.photo === 'string') {
          return new File([this.headdepartment.photo], this.headdepartment.photo, { type: 'text/plain' })
        }

        return this.headdepartment.photo
      },
      set() {
        return this.headdepartment.photo
      },
    },
  },
  watch: {
    editUuid() {
      if (this.editUuid) this.get()
    },
  },
  mounted() {
    if (this.editUuid) this.get()
  },
  methods: {
    autofillWorkDate() {
      this.headdepartment.date_of_work = new Date().toISOString().substr(0, 10)
    },

    autofillBirthDate() {
      this.headdepartment.date_of_birth = new Date().toISOString().substr(0, 10)
    },

    getFile(data) {
      this.headdepartment.photo = data
    },

    resetData() {
      this.$refs.form.reset()
    },

    async get() {
      await this.$services.ApiServices.get('headdepartment', this.editUuid).then(
        ({ data }) => {
          const photoKadis = data.data.photo == null ? null : data.data.photo.split('/').pop()
          this.headdepartment = {
            nip: data.data.nip,
            nik: data.data.nik,
            first_degree: data.data.first_degree,
            last_degree: data.data.last_degree,
            date_of_birth: data.data.date_of_birth,
            place_of_birth: data.data.place_of_birth,
            gender: data.data.gender,
            religion: data.data.religion,
            phone: data.data.phone,
            photo: data.data.photo == null ? null : data.data.photo.split('/').pop(),
            date_of_work: data.data.date_of_work,
          }
        },
        err => console.error(err),
      )
    },
  },
}
</script>

<style>
</style>
